import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/varun.dey/Desktop/Side/varundey.github.io/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hi, I am Varun. I build things that help users have a better experience on web.`}</p>
    <p>{`Currently working as a Software Engineer at `}<a parentName="p" {...{
        "href": "https://lambda.grofers.com/"
      }}>{`Grofers`}</a>{` as part of their supply chain engineering.`}</p>
    <p>{`My past gig was at `}<a parentName="p" {...{
        "href": "https://www.nortonlifelock.com/"
      }}>{`NortonLifeLock`}</a>{` (formerly `}<a parentName="p" {...{
        "href": "https://www.symantec.com"
      }}>{`Symantec`}</a>{`) where I worked with Browser Protection team to help build products for millions of our users.`}</p>
    <p>{`I enjoy watching football (Visca El Barça) and reading in my free time. Check out my `}<a parentName="p" {...{
        "href": "https://www.goodreads.com/varundey"
      }}>{`readlist`}</a>{`.`}</p>
    <p>{`You can find me on `}<a parentName="p" {...{
        "href": "https://twitter.com/AsDeyQuote"
      }}>{`Twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/varundey"
      }}>{`Github`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/varundey"
      }}>{`LinkedIn`}</a>{`, or reach me via `}<a parentName="p" {...{
        "href": "mailto:varundey20@gmail.com"
      }}>{`email`}</a>{`. I promise I won’t bite. :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      